#nav-link-header .active{
    background-color: rgb(45, 96, 190) !important;
 }

#nav-link-header {
    color: rgba(255,255,255,1) !important;
}

#nav-link-header:hover   {
    color: rgba(255,255,255,.75) !important;
}

.navbar-brand:hover{
    color: rgba(255,255,255,.75) !important;
}