.containerRelationLink {
    display: 'block';
    min-width: 800;
    padding: 5px;
    position: relative;
    overflow-y: hidden;
    overflow-x: visible;
}

.select {
    font-weight: 1000;
}