.barplottip {
  box-sizing: border-box;
  line-height: 1;
  padding: 6px;
  background: rgba(255, 255, 255, 0.75);
  color: rgb(0, 0, 0);
  border-radius: 4px;
  border-color: rgb(119, 119, 119);
  border-width: 3px !important;
  border-style: solid;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  white-space: nowrap;
  }
  
  /* Creates a small triangle extender for the tooltip 
  */
  .barplottip:before {
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    width: 100%;
    line-height: 1;
    color: rgb(119, 119, 119);
    content: "\25BC";
    position: absolute;
    text-align: center;
    z-index: -1;
  }

  .barplottip:before {
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
  }
  
