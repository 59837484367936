:root {
  --background: #94c5b1;
  --background_alternative: #c5ded4;
}

.questionlist-div{
  background: var(--background);
}

.questionmenu{
    background: var(--background);
    padding: 18px;
}
  
.errorLabel{
  font-size: small;
  color: red;
}

.questionlistcollapser.collapse {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.questionlistcollapser.collapse.show {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.questionlistcollapser.collapsing {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}