.root{
  --leftadjust: 0px;
}

.stip{
    line-height: 1.5;
    display: inline-block;
    padding: 6px;
    color: rgb(0, 0, 0);
    border-style: solid;
    border-radius: 4px;
    border-color: rgb(77, 77, 77);
    border-width: 1px;
    position: absolute;
    z-index: 2;
    font-size: 14px;
    font-weight: 500;
    transform: translate(-50%,0);
    width:150px;
  }
  
  /* Creates a small triangle extender for the tooltip 
  I have no idea why the transform is necessary.
  */

  .stiparrow {
    display: block;
    position: absolute;
    color: rgba(77, 77, 77, 0.8);
    font-size: 14px;
    top:0px;
  }

  .clicktip {
    position: absolute;
    text-align: left;
    line-height: 1.5;
    padding: 6px;
    color: rgb(0, 0, 0);
    border-style: solid;
    border-radius: 4px;
    border-color: #000000;
    border-width: 1px;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    width: 250px;
    transform: translate(-50%,0);
    z-index: 2;
    }

    .clicktiparrow {
      display: block;
      position: absolute;
      color: rgb(0, 0, 0);
      font-size: 14px;
      top:0px;
    }
    
    
    .buttontip {
      line-height: 1.5;
      padding: 6px;
      color: rgb(0, 0, 0);
      background-color: #8fafd4d8;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px;
      border-color: #535353;
      position: absolute;
      font-size: 14px;
      font-weight: 400;
      max-width: 250px;
      z-index: 2;
      }
      
      /* Creates a small triangle extender for the tooltip 
      */
      .buttontip:before {
        height:14px;
        box-sizing: border-box;
        font-size: 14px;
        width: 100%;
        line-height: 1;
        padding: 0px;
        margin: 0px;
        color:  #535353;
        content: "\25BC";
        position: absolute;
        text-align: center;
        z-index:1;
      }
      
      .buttontip.n:before {
        top: calc(100% - 2px);
        left: 0;
      }
  
      .buttontip.small {
        max-width: 100px;
        word-wrap: break-word;
      }
.aslink {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #0000EE;
  cursor: pointer;
}

.aslink:hover{
  text-decoration:underline;
}
        
